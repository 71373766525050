import { Resource as ResourceType } from '../types';

export const contract: ResourceType = {
  name: 'contracts',
  label: 'resource.contracts.label',
  hideResource: false,
  attributes: [
    {
      key: 'id',
      label: 'resource.contracts.field.id.label',
      type: 'text',
    },
    {
      key: 'candidacy_id',
      label: 'resource.contracts.field.candidacy_id.label',
      type: 'reference',
      reference: 'candidacies',
    },
    {
      key: 'candidacy_owner_id',
      label: 'resource.proposals.field.candidacy_owner_name.label',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'company_legal_information_id',
      label: 'resource.proposals.field.company_legal_information_id.label',
      type: 'reference',
      reference: 'legal_informations',
    },
    {
      key: 'proposal_id',
      label: 'resource.contracts.field.proposal_id.label',
      type: 'reference',
      reference: 'proposals',
    },
    {
      key: 'mission_id',
      label: 'resource.contracts.field.mission_id.label',
      type: 'reference',
      reference: 'missions',
    },
    {
      key: 'client_owner_id',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'begin_date',
      label: 'resource.contracts.field.begin_date.label',
      type: 'date',
    },
    {
      key: 'end_date',
      label: 'resource.contracts.field.end_date.label',
      type: 'date',
    },
    {
      key: 'status',
      label: 'resource.contracts.field.status.label',
      type: 'select',
      choices: [
        {
          id: 'running',
          name: 'resource.contracts.field.status.choices.running.label',
        },
        {
          id: 'finished',
          name: 'resource.contracts.field.status.choices.finished.label',
        },
      ],
    },
    {
      key: 'created_at',
      label: 'resource.contracts.field.created_at.label',
      type: 'date',
    },
    {
      key: 'updated_at',
      label: 'resource.users.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'quotation_kind',
      label: 'resource.contracts.field.quotation_kind.label',
      type: 'select',
      choices: [
        {
          id: 'forfeit',
          name: 'resource.quotations.field.kind.choices.forfeit.label',
        },
        {
          id: 'technical_assistance',
          name: 'resource.quotations.field.kind.choices.technical_assistance.label',
        },
      ],
    },
    {
      key: 'total_duty',
      label: 'resource.quotations.field.total_duty.label',
      type: 'number',
    },
    {
      key: 'admin_total_duty',
      label: 'resource.quotations.field.admin_total_duty.label',
      type: 'number',
    },
    {
      key: 'candidacy_company_id',
      type: 'reference',
      reference: 'companies',
    },
  ],
  pages: {
    list: {
      hasEditButton: true,
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      filters: [
        {
          source: 'status',
          type: 'multipleSelect',
          optionsFilter: {
            className: 'multipleSelectFilter',
          },
        },
      ],
      fields: [
        {
          source: 'id',
          sortable: false,
        },
        {
          source: 'candidacy_id',
          child: {
            source: 'candidacies.name',
          },
          sortable: false,
        },
        {
          source: 'proposal_id',
          child: {
            source: 'proposals.guid',
          },
          sortable: false,
        },
        {
          source: 'mission_id',
          child: {
            source: 'missions.name',
          },
        },
        {
          source: 'status',
        },
        {
          source: 'begin_date',
        },
        {
          source: 'end_date',
        },
        {
          source: 'created_at',
        },
      ],
    },
    edit: {
      left: {
        tabs: [
          {
            key: 'contract',
            title: 'resource.contracts.tab.information.title',
            sections: [
              {
                key: 'information',
                title: 'resource.contract.section.information.title',
                layout: [
                  ['status'],
                  ['begin_date', 'end_date'],
                  ['quotation_kind'],
                  ['total_duty', 'admin_total_duty'],
                ],
                inputs: {
                  status: {
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  begin_date: {
                    width: 6,
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  end_date: {
                    width: 6,
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  quotation_kind: {
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  total_duty: {
                    width: 6,
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  admin_total_duty: {
                    width: 6,
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                },
              },
              {
                key: 'contract_milestones',
                title: 'resource.contract.section.contract_milestone.title',
                layout: [],
                inputs: {},
                list: {
                  hasCreateButton: false,
                  actions: [
                    {
                      name: 'redirect_to_contract_milestone_creation',
                      type: 'redirect',
                      label:
                        'resource.contracts.bulk_action_buttons.create_a_contract_milestone.label',
                      extraUrl: '/contract_milestones/create',
                      hiddenInput: [
                        {
                          key: 'contract_id',
                          value: 'id',
                        },
                        {
                          key: 'quotation_kind',
                          value: 'quotation_kind',
                        },
                      ],
                    },
                  ],
                  resource_to_fetch: 'contract_milestones',
                  filter_resource: 'contract',
                  hasEditButton: true,
                  fields: [
                    {
                      source: 'title',
                    },
                    {
                      source: 'begin_date',
                    },
                    {
                      source: 'end_date',
                    },
                    {
                      source: 'units_worked',
                    },
                    {
                      source: 'admin_units_worked',
                    },
                    {
                      source: 'price',
                    },
                    {
                      source: 'admin_price',
                    },
                    {
                      source: 'status',
                    },
                    {
                      source: 'contract_milestone_id',
                      child: {
                        source: 'contract_milestones.id',
                      },
                    },
                    {
                      source: 'accepted_at',
                    },
                    {
                      source: 'payment_period',
                    },
                    {
                      source: 'discount',
                    },
                  ],
                },
              },
            ],
          },
          {
            key: 'invoice',
            title: 'resource.contracts.tab.invoice.title',
            sections: [
              {
                key: 'invoice',
                title: 'resource.contract.section.invoice.title',
                layout: [['status']],
                inputs: {},
                list: {
                  hasCreateButton: false,
                  resource_to_fetch: 'invoices',
                  filter_resource: 'contract',
                  hasEditButton: true,
                  fields: [
                    {
                      source: 'guid',
                    },
                    {
                      source: 'status',
                    },
                    {
                      source: 'user_kind',
                    },
                    {
                      source: 'created_at',
                    },
                    {
                      source: 'due_date',
                    },
                    {
                      source: 'pdfs',
                      sortable: false,
                      extensions: [
                        {
                          key: 'multipleLangFiles',
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.contract_milestones.section.details.title',
              layout: [['id'], ['created_at', 'updated_at']],
              inputs: {
                id: {
                  disabled: true,
                },
                created_at: {
                  disabled: true,
                  width: 6,
                },
                updated_at: {
                  disabled: true,
                  width: 6,
                },
              },
            },
          ],
        },
        {
          sections: [
            {
              key: 'link',
              title: 'resource.companies.section.link.title',
              layout: [
                ['candidacy_owner_id'],
                ['company_name'],
                ['candidacy_id'],
                ['proposal_id'],
                ['company_legal_information_id'],
                ['client_owner_id'],
                ['client_company_name'],
                ['mission_id'],
              ],
              inputs: {},
              fields: {
                candidacy_owner_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'user.full_name',
                  },
                },
                company_name: {
                  source: 'candidacy_owner_id',
                  addLabel: true,
                  link: false,
                  label: 'resource.users.field.company_name_profile.label',
                  child: {
                    type: 'text',
                    source: 'users.company_name',
                  },
                },
                candidacy_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'candidacy.name',
                  },
                },
                proposal_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'proposal.guid',
                  },
                },
                company_legal_information_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'company_legal_information.id',
                  },
                },
                client_owner_id: {
                  source: 'client_owner_id',
                  addLabel: true,
                  label: 'resource.candidacies.field.company_name.label',
                  child: {
                    type: 'text',
                    source: 'users.full_name',
                  },
                },
                client_company_name: {
                  source: 'client_owner_id',
                  addLabel: true,
                  link: false,
                  label: 'resource.missions.field.company_id.label',
                  child: {
                    type: 'text',
                    source: 'users.company_name',
                  },
                },
                mission_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'mission.name',
                  },
                },
              },
            },
          ],
        },
      ],
    },
  },
};
