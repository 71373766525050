import { Resource as ResourceType } from '../types';

export const quotation: ResourceType = {
  name: 'quotations',
  label: 'resource.quotations.label',
  hideResource: true,
  attributes: [
    {
      key: 'id',
      label: 'resource.quotations.field.id.label',
      type: 'text',
    },
    {
      key: 'proposal_id',
      label: 'resource.quotations.field.proposal_id.label',
      type: 'text',
    },
    {
      key: 'kind',
      label: 'resource.quotations.field.kind.label',
      type: 'text',
    },
    {
      key: 'begin_date',
      label: 'resource.quotations.field.begin_at.label',
      type: 'date',
    },
    {
      key: 'end_date',
      label: 'resource.quotations.field.end_at.label',
      type: 'date',
    },
    {
      key: 'vat',
      label: 'resource.quotations.field.vat.label',
      type: 'text',
    },
    {
      key: 'admin_vat',
      label: 'resource.quotations.field.admin_vat.label',
      type: 'text',
    },
    {
      key: 'cost_per_unit',
      label: 'resource.quotations.field.cost_per_unit.label',
      type: 'text',
    },
    {
      key: 'admin_cost_per_unit',
      label: 'resource.quotations.field.admin_cost_per_unit.label',
      type: 'text',
    },
    {
      key: 'quantity',
      label: 'resource.quotations.field.work_days.label',
      type: 'text',
    },
    {
      key: 'created_at',
      label: 'resource.quotations.field.created_at.label',
      type: 'date',
    },
    {
      key: 'updated_at',
      label: 'resource.quotations.field.updated_at.label',
      type: 'date',
    },
  ],
};
