import { Resource as ResourceType } from '../types';

export const candidacy: ResourceType = {
  name: 'candidacies',
  label: 'resource.candidacies.label',
  hideResource: false,
  attributes: [
    {
      key: 'id',
      label: 'resource.candidacies.field.id.label',
      type: 'text',
    },
    {
      key: 'name',
      label: 'resource.candidacies.field.name.label',
      type: 'text',
    },
    {
      key: 'owner_id',
      label: 'resource.candidacies.field.owner_id.label',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'status',
      label: 'resource.candidacies.field.status.label',
      type: 'select',
      choices: [
        {
          id: 'waiting_for_validation',
          name: 'resource.candidacies.field.status.choices.waiting_for_validation.label',
        },
        {
          id: 'under_review',
          name: 'resource.candidacies.field.status.choices.under_review.label',
        },
        {
          id: 'step_one',
          name: 'resource.candidacies.field.status.choices.step_one.label',
        },
        {
          id: 'step_two',
          name: 'resource.candidacies.field.status.choices.step_two.label',
        },
        {
          id: 'step_three',
          name: 'resource.candidacies.field.status.choices.step_three.label',
        },
        {
          id: 'step_four',
          name: 'resource.candidacies.field.status.choices.step_four.label',
        },
        {
          id: 'archived',
          name: 'resource.candidacies.field.status.choices.archived.label',
        },
      ],
    },
    {
      key: 'validated',
      label: 'resource.candidacies.field.validated.label',
      type: 'boolean',
    },
    {
      key: 'mission_id',
      type: 'reference',
      reference: 'missions',
    },
    {
      key: 'proposal_id',
      label: 'resource.candidacies.field.proposal_id.label',
      type: 'reference',
      reference: 'proposals',
    },
    {
      key: 'contract_id',
      label: 'resource.candidacies.field.contract_id.label',
      type: 'reference',
      reference: 'contracts',
    },
    {
      key: 'company_id',
      label: 'resource.users.field.company_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'created_at',
      label: 'resource.candidacies.field.created_at.label',
      type: 'date',
    },
    {
      key: 'updated_at',
      label: 'resource.candidacies.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'validated_at',
      label: 'resource.candidacies.field.validated_at.label',
      type: 'date',
    },
    {
      key: 'position',
      label: 'resource.candidacies.field.position.label',
      type: 'number',
    },
    {
      key: 'kind',
      label: 'resource.candidacies.field.kind.label',
      type: 'select',
      choices: [
        {
          id: 'solo',
          name: 'resource.candidacies.field.kind.choices.solo.label',
        },
      ],
    },
    {
      key: 'request_text',
      label: 'resource.candidacies.field.request_text.label',
      type: 'text',
      multiline: true,
    },
    {
      key: 'expert_fo_link',
      label: 'resource.candidacies.field.expert_fo_link.label',
      type: 'frontOfficeLink',
      externalLink: {
        source: 'company_id',
        path: 'experts',
      },
    },
    {
      key: 'source',
      label: 'resource.candidacies.field.source.label',
      type: 'text',
    },
    {
      key: 'matched',
      label: 'resource.candidacies.field.matched.label',
      type: 'boolean',
    },
    {
      key: 'match_percentage',
      label: 'resource.candidacies.field.match_percentage.label',
      type: 'number',
    },
    {
      key: 'invited',
      label: 'resource.candidacies.field.invited.label',
      type: 'boolean',
    },
    {
      key: 'invited_at',
      label: 'resource.candidacies.field.invited_at.label',
      type: 'date',
    },
    {
      key: 'blacklisted',
      label: 'resource.candidacies.field.blacklisted.label',
      type: 'boolean',
    },
    {
      key: 'blacklisted_at',
      label: 'resource.candidacies.field.blacklisted_at.label',
      type: 'date',
    },
    {
      key: 'blacklisted_reason',
      label: 'resource.candidacies.field.blacklisted_reason.label',
      type: 'text',
    },
    {
      key: 'blacklisted_text',
      label: 'resource.candidacies.field.blacklisted_text.label',
      type: 'text',
    },
    {
      key: 'archived',
      label: 'resource.candidacies.field.archived.label',
      type: 'boolean',
    },
    {
      key: 'mission_owner_id',
      label: 'resource.missions.field.company_id.label',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'company_solo',
      label: 'resource.companies.field.solo?.label',
      type: 'boolean',
    },
    {
      key: 'daily_rate',
      label: 'resource.candidacies.field.daily_rate.label',
      type: 'number',
    },
    {
      key: 'salary',
      label: 'resource.candidacies.field.salary.label',
      type: 'number',
    },
    {
      key: 'admin_daily_rate',
      label: 'resource.candidacies.field.admin_daily_rate.label',
      type: 'number',
    },
    {
      key: 'attachment',
      label: 'resource.candidacies.field.attachment.label',
      type: 'buttons',
    },
    {
      key: 'mission_contract_types',
      label: 'resource.candidacies.field.mission_contract_types.label',
      type: 'select',
      choices: [
        {
          id: 'fulltime_contract',
          name: 'resource.companies.field.contract_types.choices.fulltime_contract.label',
        },
        {
          id: 'missions',
          name: 'resource.companies.field.contract_types.choices.missions.label',
        },
      ],
    },
  ],
  pages: {
    list: {
      hasEditButton: true,
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      bulkActionButtons: [
        {
          name: 'validate',
          type: 'update',
          label: 'resource.candidacies.bulk_action_buttons.validate.label',
          values: {
            validated: true,
          },
        },
        {
          name: 'archive',
          type: 'update',
          label: 'resource.candidacies.bulk_action_buttons.archive.label',
          values: { archived: true },
        },
      ],
      filters: [
        {
          source: 'mission_id',
          type: 'reference',
          child: {
            type: 'autocomplete',
            optionText: 'name',
            // Options to pass to ReferenceInput
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
        {
          source: 'status',
          type: 'multipleSelect',
          optionsFilter: {
            className: 'multipleSelectFilter',
          },
        },
        {
          source: 'company_id',
          type: 'reference',
          child: {
            type: 'autocomplete',
            optionText: 'name',
            // Options to pass to ReferenceInput
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
      ],
      fields: [
        {
          source: 'name',
        },
        {
          source: 'status',
        },
        {
          source: 'source',
        },
        {
          source: 'validated',
          sortable: false,
        },
        {
          source: 'mission_id',
          sortable: false,
          label: 'resource.candidacies.field.mission_id.label',
          child: {
            source: 'missions.name',
          },
        },
        {
          source: 'mission_owner_id',
          sortable: false,
          child: {
            source: 'users.company_name',
          },
        },
        {
          source: 'owner_id',
          sortable: false,
          child: {
            source: 'users.full_name',
          },
        },
        {
          source: 'owner_id',
          sortable: false,
          label: 'resource.users.field.email.label',
          child: {
            source: 'users.email',
          },
        },
        {
          source: 'owner_id',
          sortable: false,
          label: 'resource.users.field.phone.label',
          child: {
            source: 'users.phone',
          },
        },
        {
          source: 'owner_id',
          sortable: false,
          label: 'resource.users.field.linkedin_vanity_name.label',
          icon: 'LinkedIn',
          child: {
            source: 'users.linkedin_vanity_name',
          },
        },
        {
          source: 'company_id',
          title: 'cv',
          sortable: false,
          type: 'reference',
          link: false,
          label: 'resource.companies.field.cv_download_url.label',
          child: {
            source: 'companies.cv_download_url',
            type: 'file',
          },
        },
        {
          source: 'expert_fo_link',
          sortable: false,
          icon: 'AccountCircle',
        },
        {
          source: 'created_at',
        },
      ],
    },
    create: {
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.candidacies.tab.information.title',
            sections: [
              {
                key: 'entity',
                title: 'resource.candidacies.section.information.title',
                layout: [['owner_id', 'selected_mission']],
                inputs: {
                  owner_id: {
                    type: 'text',
                    disabled: true,
                  },
                  selected_mission: {
                    source: 'mission_id',
                    type: 'reference',
                    reference: 'missions',
                    label: 'resource.missions.field.name.label',
                    filter: { status: 'published' },
                    child: {
                      type: 'autocomplete',
                      optionText: 'name',
                      extensions: [
                        {
                          key: 'concatenateParams',
                          params: {
                            first: 'name',
                            second: 'company_name',
                          },
                        },
                      ],
                      options: {
                        perPage: 10,
                        allowEmpty: false,
                      },
                    },
                  },
                },
              },
            ],
          },
        ],
      },
    },
    edit: {
      actions: [
        {
          name: 'validate',
          type: 'update',
          label: 'resource.candidacies.bulk_action_buttons.validate.label',
          values: { validated: true },
          extraUrl: '/',
          disabled: [{ property: 'can_validate', op: 'eq', value: false }],
        },
        {
          name: 'archive',
          type: 'update',
          label: 'resource.candidacies.bulk_action_buttons.archive.label',
          values: { archived: true },
          extraUrl: '/',
          disabled: [{ property: 'can_archive', op: 'eq', value: false }],
        },
      ],
      left: {
        tabs: [
          {
            key: 'information',
            title: 'resource.candidacies.tab.information.title',
            sections: [
              {
                key: 'entity',
                title: 'resource.candidacies.section.information.title',
                layout: [
                  ['name'],
                  ['status', 'position'],
                  ['source', 'kind'],
                  ['request_text'],
                  ['attachment'],
                  ['daily_rate', 'admin_daily_rate'],
                  ['salary'],
                ],
                inputs: {
                  name: {
                    validators: [{ key: 'required' }],
                  },
                  status: {
                    width: 6,
                    validators: [{ key: 'required' }],
                    extensions: [
                      {
                        key: 'disabledCandidacyStatus',
                      },
                    ],
                  },
                  position: {
                    width: 6,
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  source: {
                    width: 6,
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  kind: {
                    width: 6,
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  request_text: {},
                  daily_rate: {
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'mission_contract_types',
                          op: 'eq',
                          value: 'fulltime_contract',
                        },
                        {
                          property: 'company_solo',
                          op: 'eq',
                          value: false,
                        },
                      ],
                    },
                  },
                  admin_daily_rate: {
                    width: 6,
                    rules: {
                      hide: [
                        {
                          property: 'mission_contract_types',
                          op: 'eq',
                          value: 'fulltime_contract',
                        },
                        {
                          property: 'company_solo',
                          op: 'eq',
                          value: false,
                        },
                      ],
                    },
                  },
                  salary: {
                    rules: {
                      hide: [
                        {
                          property: 'mission_contract_types',
                          op: 'eq',
                          value: 'missions',
                        },
                        {
                          property: 'company_solo',
                          op: 'eq',
                          value: false,
                        },
                      ],
                    },
                  },
                },
                fields: {
                  attachment: {
                    addLabel: true,
                    extensions: [
                      {
                        key: 'attachmentsFiles',
                      },
                    ],
                  },
                },
              },
              {
                key: 'additional_information',
                title: 'resource.candidacies.section.monitoring.title',
                layout: [
                  ['matched', 'match_percentage'],
                  ['invited', 'invited_at'],
                ],
                inputs: {
                  matched: {
                    width: 6,
                    disabled: true,
                  },
                  match_percentage: {
                    width: 6,
                    disabled: true,
                  },
                  invited: {
                    width: 6,
                    disabled: true,
                  },
                  invited_at: {
                    width: 6,
                    disabled: true,
                  },
                },
              },
              {
                key: 'blacklist',
                title: 'resource.candidacies.section.blacklisted.title',
                layout: [
                  [
                    'blacklisted',
                    'blacklisted_at',
                    'blacklisted_reason',
                    'blacklisted_text',
                  ],
                  ['archived'],
                ],
                inputs: {
                  blacklisted: {
                    width: 6,
                    disabled: true,
                  },
                  blacklisted_at: {
                    width: 6,
                    disabled: true,
                  },
                  blacklisted_reason: {
                    width: 6,
                    disabled: true,
                  },
                  blacklisted_text: {
                    width: 6,
                    disabled: true,
                  },
                  archived: {
                    disabled: true,
                  },
                },
              },
            ],
          },
          {
            key: 'candidates',
            title: 'resource.candidacy.tab.candidate.title',
            rules: {
              hide: [
                {
                  property: 'company_solo',
                  op: 'eq',
                  value: true,
                },
              ],
            },
            sections: [
              {
                key: 'candidates',
                title:
                  'resource.proposals.section.information.milestones.title',
                layout: [],
                inputs: {},
                list: {
                  hasEditButton: true,
                  actions: [],
                  resource_to_fetch: 'candidates',
                  filter_resource: 'candidacy',
                  fields: [
                    {
                      source: 'collaborator.last_name',
                    },
                    {
                      source: 'collaborator.first_name',
                    },
                    {
                      source: 'collaborator.job_name',
                    },
                    {
                      source: 'daily_rate',
                    },
                    {
                      source: 'admin_daily_rate',
                    },
                    {
                      source: 'outsourcing',
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.candidacies.section.details.title',
              layout: [
                ['id'],
                ['created_at', 'updated_at'],
                ['validated_at', 'owner_id'],
                ['company_owner_id'],
                ['mission_id'],
                ['mission_owner_id'],
                ['client_company_name'],
                ['proposal_id'],
                ['contract_id'],
              ],
              inputs: {
                id: {
                  disabled: true,
                },
                created_at: {
                  disabled: true,
                  width: 6,
                },
                updated_at: {
                  disabled: true,
                  width: 6,
                },
                validated_at: {
                  disabled: true,
                  width: 6,
                },
              },
              fields: {
                owner_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'users.full_name',
                  },
                },
                company_owner_id: {
                  source: 'owner_id',
                  addLabel: true,
                  link: false,
                  label: 'resource.users.field.company_name_profile.label',
                  child: {
                    type: 'text',
                    source: 'users.company_name',
                  },
                },
                mission_id: {
                  addLabel: true,
                  label: 'resource.candidacies.field.mission_id.label',
                  child: {
                    type: 'text',
                    source: 'mission.name',
                  },
                },
                mission_owner_id: {
                  addLabel: true,
                  label: 'resource.candidacies.field.mission_owner_id.label',
                  child: {
                    type: 'text',
                    source: 'user.full_name',
                  },
                },
                client_company_name: {
                  source: 'mission_owner_id',
                  addLabel: true,
                  link: false,
                  label: 'resource.missions.field.company_id.label',
                  child: {
                    type: 'text',
                    source: 'user.company_name',
                  },
                },
                proposal_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'proposal.guid',
                  },
                },
                contract_id: {
                  addLabel: true,
                  child: {
                    type: 'text',
                    source: 'contract.id',
                  },
                },
              },
            },
          ],
        },
        {
          sections: [
            {
              key: 'history',
              title: 'resource.companies.section.history.title',
              layout: [],
              inputs: {},
              history: true,
            },
          ],
        },
      ],
    },
  },
};
