import { Config as ConfigType } from './types';
import { user } from './mapo/user';
import { expert } from './mapo/expert';
import { client } from './mapo/client';
import { company } from './mapo/company';
import { mission } from './mapo/mission';
import { invitation } from './mapo/invitation';
import { candidate } from './mapo/candidate';
import { candidacy } from './mapo/candidacy';
import { proposal } from './mapo/proposal';
import { quotation } from './mapo/quotation';
import { charge } from './mapo/charge';
import { milestone } from './mapo/milestone';
import { contract } from './mapo/contract';
import { contractMilestone } from './mapo/contractMilestone';
import { invoice } from './mapo/invoice';
import { recommendation } from './mapo/recommendation';
import { legalInformation } from './mapo/legalInformation';
import { cgu } from './mapo/cgu';
import { match } from './mapo/match';
import { document } from './mapo/document';
import { subCategory } from './mapo/subCategory';
import { documentFile } from './mapo/documentFile';
import { documentsCompanies } from './mapo/documentsCompanies';
import { tag } from './mapo/tag';

export const config: ConfigType = {
  login: 'password',
  hash: '4rr0tdosatvzb70almrhxtkbk',
  locale: 'fr',
  name: 'mapo',
  dashboard: true,
  theme: {
    primary: '#187879',
    secondary: '#000A21',
    info: '#E0A469',
  },
  defaultCountry: 'fr',
  preferredCountries: ['fr'],
  resources: [
    expert,
    client,
    user,
    subCategory,
    company,
    mission,
    invitation,
    candidate,
    candidacy,
    proposal,
    quotation,
    charge,
    milestone,
    contract,
    contractMilestone,
    invoice,
    recommendation,
    legalInformation,
    document,
    documentFile,
    documentsCompanies,
    cgu,
    match,
    tag,
  ],
};

export default config;
