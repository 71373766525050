import { Resource as ResourceType } from '../types';

export const documentFile: ResourceType = {
  name: 'document_files',
  label: 'resource.document_files.label',
  hideResource: false,
  attributes: [
    {
      key: 'id',
      type: 'text',
    },
    {
      key: 'document_id',
      type: 'reference',
      reference: 'documents',
    },
    {
      key: 'document_kind_id',
      type: 'reference',
      reference: 'tags',
    },
    {
      key: 'leader_id',
      label: 'resource.document_files.field.leader_id.label',
      type: 'reference',
      reference: 'users',
    },
    {
      key: 'created_at',
      label: 'resource.documents.field.created_at.label',
      type: 'date',
    },
    {
      key: 'updated_at',
      label: 'resource.documents.field.updated_at.label',
      type: 'date',
    },
    {
      key: 'accepted_at',
      label: 'resource.documents.field.accepted_at.label',
      type: 'date',
    },
    {
      key: 'refused_at',
      label: 'resource.documents.field.refused_at.label',
      type: 'date',
    },
    {
      key: 'status',
      label: 'resource.document_files.status.label',
      type: 'select',
      choices: [
        {
          id: 'pending',
          name: 'resource.document_files.field.status.pending.value',
        },
        {
          id: 'accepted',
          name: 'resource.document_files.field.status.accepted.value',
        },
        {
          id: 'refused',
          name: 'resource.document_files.field.status.refused.value',
        },
        {
          id: 'expired',
          name: 'resource.document_files.field.status.expired.value',
        },
        {
          id: 'archived',
          name: 'resource.document_files.field.status.archived.label',
        },
      ],
    },
    {
      key: 'company_id',
      label: 'resource.legal_informations.field.company_id.label',
      type: 'reference',
      reference: 'companies',
    },
    {
      key: 'description',
      label: 'resource.document_files.field.description.label',
      type: 'text',
    },
    {
      key: 'issuing_agency',
      label: 'resource.document_files.field.issuing_agency.label',
      type: 'text',
    },
    {
      key: 'issue_date',
      label: 'resource.documents.field.issue_date.label',
      type: 'date',
    },
    {
      key: 'attachments',
      label: 'resource.documents.field.attachments.label',
      type: 'file',
    },
    {
      key: 'document',
      type: 'object',
      shape: [
        {
          key: 'name',
          label: 'resource.documents.field.name.label',
          type: 'text',
        },
        {
          key: 'description',
          label: 'resource.documents.field.description.label',
          type: 'text',
        },
        {
          key: 'kind_name',
          label: 'resource.documents.field.kind_name.label',
          type: 'text',
        },
        {
          key: 'validity_period',
          label: 'resource.documents.field.validity_period.label',
          type: 'select',
          choices: [
            {
              id: 'unlimited',
              name: 'resource.documents.field.validity_period.choices.unlimited.label',
            },
            {
              id: 'duration_from_file_date',
              name: 'resource.documents.field.validity_period.choices.duration_from_file_date.label',
            },
          ],
        },
        {
          key: 'duration',
          label: 'resource.documents.field.duration.label',
          type: 'number',
        },
        {
          key: 'condition',
          label: 'resource.documents.field.condition.label',
          type: 'select',
          choices: [
            {
              id: 'optional',
              name: 'resource.documents.field.condition.choices.optional.label',
            },
            {
              id: 'mandatory_to_apply',
              name: 'resource.documents.field.condition.choices.mandatory_to_apply.label',
            },
            {
              id: 'mandatory_to_send_proposal',
              name: 'resource.documents.field.condition.choices.mandatory_to_send_proposal.label',
            },
          ],
        },
        {
          key: 'client_condition',
          label: 'resource.documents.field.client_condition.label',
          type: 'select',
          choices: [
            {
              id: 'optional',
              name: 'resource.documents.field.client_condition.choices.optional.label',
            },
            {
              id: 'mandatory_to_publish_mission',
              name: 'resource.documents.field.client_condition.choices.mandatory_to_publish_mission.label',
            },
          ],
        },
        {
          key: 'user_kind',
          label: 'resource.documents.field.user_kind.label',
          type: 'select',
          choices: [
            {
              id: 'expert',
              name: 'resource.documents.field.user_kind.choices.expert.label',
            },
            {
              id: 'client',
              name: 'resource.documents.field.user_kind.choices.client.label',
            },
            {
              id: 'mixed',
              name: 'resource.documents.field.user_kind.choices.mixed.label',
            },
          ],
        },
      ],
    },
    {
      key: 'document_archived',
      label: 'resource.documents.field.document_archived.label',
      type: 'boolean',
    },
  ],
  pages: {
    list: {
      hasCreateButton: true,
      hasEditButton: true,
      search: {
        enabled: true,
        label: 'filter.search.label',
      },
      bulkActionButtons: [
        {
          name: 'validate',
          type: 'update',
          label: 'resource.candidacies.bulk_action_buttons.validate.label',
          values: {
            validated: true,
          },
        },
        {
          name: 'unvalidate',
          type: 'update',
          label:
            'resource.contract_milestones.bulk_action_buttons.unvalidate.label',
          values: {
            refused: true,
          },
          // Following settings are only useful for update with confirmation
          withConfirmation: true,
          confirmation: {
            content: 'modal.document_files.unvalidate.content',
          },
        },
        {
          name: 'archive',
          type: 'update',
          label: 'resource.candidacies.bulk_action_buttons.archive.label',
          values: { archived: true },
        },
      ],
      filters: [
        {
          source: 'document_kind_id',
          label: 'resource.documents.field.kind_name.label',
          filter: { kind: 'document' },
          child: {
            type: 'autocomplete',
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
        {
          source: 'status',
        },
        {
          source: 'company_id',
          type: 'reference',
          child: {
            type: 'autocomplete',
            // Options to pass to ReferenceInput
            options: {
              perPage: 10,
              allowEmpty: false,
            },
          },
        },
        {
          source: 'document_archived',
        },
      ],
      fields: [
        {
          source: 'document_id',
          child: {
            source: 'documents.name',
          },
        },
        {
          source: 'leader_id',
          child: {
            source: 'users.full_name',
          },
        },
        {
          source: 'document_id',
          link: false,
          label: 'resource.documents.field.kind_name.label',
          child: {
            source: 'documents.kind_name',
          },
        },
        {
          source: 'created_at',
        },
        {
          source: 'status',
        },
        {
          source: 'attachments',
          type: 'buttons',
          extensions: [
            {
              key: 'attachmentsFiles',
            },
          ],
        },
      ],
    },
    create: {
      actions: [],
      left: {
        tabs: [],
      },
    },
    edit: {
      actions: [],
      left: {
        tabs: [
          {
            key: 'document_files',
            title: 'resource.document_files.tab.information.title',
            sections: [
              {
                key: 'document',
                title: 'resource.document.section.information.title',
                layout: [
                  ['document.name'],
                  ['document.description'],
                  ['document.kind_name'],
                  ['document.validity_period'],
                  ['document.duration'],
                  ['document.user_kind'],
                  ['document.condition'],
                  ['document.client_condition'],
                ],
                inputs: {
                  'document.name': {
                    disabled: true,
                  },
                  'document.description': {
                    disabled: true,
                  },
                  'document.kind_name': {
                    disabled: true,
                  },
                  'document.validity_period': {
                    disabled: true,
                  },
                  'document.duration': {
                    disabled: true,
                  },
                  'document.user_kind': {
                    disabled: true,
                    validators: [{ key: 'required' }],
                  },
                  'document.condition': {
                    disabled: true,
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'document.user_kind',
                          op: 'eq',
                          value: 'client',
                        },
                        {
                          property: 'document.user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                  },
                  'document.client_condition': {
                    disabled: true,
                    validators: [{ key: 'required' }],
                    rules: {
                      hide: [
                        {
                          property: 'document.user_kind',
                          op: 'eq',
                          value: 'expert',
                        },
                        {
                          property: 'document.user_kind',
                          op: 'eq',
                          value: null,
                        },
                      ],
                    },
                  },
                },
              },
              {
                key: 'document_files',
                title: 'resource.document_files.section.information.title',
                layout: [
                  ['issuing_agency'],
                  ['issue_date'],
                  ['status'],
                  ['attachments'],
                  ['description'],
                ],
                inputs: {
                  issuing_agency: {
                    validators: [{ key: 'required' }],
                  },
                  issue_date: {
                    validators: [{ key: 'required' }],
                  },
                  status: {
                    validators: [{ key: 'required' }],
                  },
                  attachments: {
                    validators: [{ key: 'required' }],
                  },
                  description: {},
                },
              },
            ],
          },
        ],
      },
      right: [
        {
          sections: [
            {
              key: 'details',
              title: 'resource.document.section.details.title',
              layout: [
                ['id'],
                ['created_at', 'updated_at'],
                ['accepted_at', 'refused_at'],
                ['leader_id'],
                ['document_id'],
              ],
              inputs: {
                id: {
                  disabled: true,
                },
                created_at: {
                  disabled: true,
                  width: 6,
                },
                updated_at: {
                  disabled: true,
                  width: 6,
                },
                accepted_at: {
                  disabled: true,
                  width: 6,
                },
                refused_at: {
                  disabled: true,
                  width: 6,
                },
              },
              fields: {
                leader_id: {
                  addLabel: true,
                  label: 'resource.document_files.field.expert.label',
                  child: {
                    type: 'text',
                    source: 'users.full_name',
                  },
                },
                document_id: {
                  addLabel: true,
                  label: 'resource.document_files.field.document.label',
                  child: {
                    type: 'text',
                    source: 'documents.name',
                  },
                },
              },
            },
          ],
        },
      ],
    },
  },
};
